import logo from "./DDF_Logo_Hor_FINAL_Alpha.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p></p>
        <p>Thank you for your interest in Digital Dream Forge!</p>
        <p>
          Please see our job postings on <strong>Indeed</strong> and to submit your resume:
        </p>
        <p>
          <a href="https://www.indeed.com/cmp/Digital-Dream-Forge/jobs">
            <strong>https://www.indeed.com/cmp/Digital-Dream-Forge/jobs</strong>
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
